import React from 'react';
import {
    Link,
    Redirect,
} from "react-router-dom";

class SigninForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            getsome: this.props.passedMethod,
            redirectUser: false,
            redirectForgot: false,
            redirectQuiz: false,
            redirectDisclaimer: false,
        }
        this.props.signout();
    }


    signIn = () => {
        // this.props.setLoading(true);
        var username = document.getElementById('email').value;
        var pass = document.getElementById('pass').value;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email:username,
                pass:pass
            }),
        };
        fetch("https://bucketlist.burgertechnologies.com/api/accounts/read.php",requestOptions).then(res => res.json()).then(result => {
            console.log(result);
            if(result.result)
            {
                this.setState({
                    user:result,
                    redirectUser: true,
                },() => {
                    this.props.signin(result.Auth,result.User,result.Name,result.Email);
                })    
            }else{
                alert("Failed To Sign In");
            }
            
        });
        
        //     if (response === 'success' && auth === 0 && name !== null) {
        //         // this.props.setLoading(false);
        //         this.props.signin(0, auth, user, prize, points, school, grade, name, email, phone, quiz);
                
        //         if(disclaimer === 0){
        //             this.setState({ redirectDisclaimer: true});
        //         }else if (quiz > 0) {
        //             this.setState({ redirectQuiz: true });//quiz
        //         }
        //         this.setState({
        //             redirectUser: true,
        //         })
        //     } else if (response === 'success' && auth === 52 && name !== null) {
        //         // this.props.setLoading(false);
        //         this.props.signin(4, auth, user, prize, points, school, grade, name, email, phone, quiz);
        //         this.setState({
        //             redirectTeacher: true,
        //         })
        //     } else if (response === 'success' && auth === 97 && name !== null) {
        //         // this.props.setLoading(false);
        //         this.props.signin(5, auth, user, prize, points, school, grade, name, email, phone, quiz);
        //         this.setState({
        //             redirectAdmin: true,
        //         })
        //     } else {
        //         // this.props.setLoading(false);
        //         alert("Failed");
        //     }
        // });
        // this.props.setLoading(false);
    }


    render() {

        if (this.state.redirectUser) {
            return (<Redirect push to={"/user"} />);
        } else if (this.state.redirectForgot) {
            return (<Redirect push to={"/forgot"} />);
        } else if (this.state.redirectDisclaimer) {
            return (<Redirect push to={"/disclaimer"}/>);
        }

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                document.getElementById('signInButton').click();
            }
        }

        return (
            <div className='signinForm'>
                <div className='signinLogoDiv'>
                    <img className='signinLogo' src="./logo192.png" alt="Bucket List logo" />
                </div>
                <table>
                    <thead></thead>
                    <tbody>
                        <tr><td>
                            <h1>Sign In</h1>
                        </td></tr>
                        <tr><td>
                            <label htmlFor="email" >Email </label>
                        </td></tr>
                        <tr><td>                
                            <input id='email' name='user' type='text' placeholder='Example@email.com'></input>
                        </td></tr>
                        <tr><td>
                            <label htmlFor="Password">Password</label>
                        </td></tr>
                        <tr><td>
                            <input id='pass' name='pass' type='password' placeholder='********' onKeyDown={handleKeyDown}></input>
                        </td></tr>
                        <tr><td>
                            <button id='signInButton' className='signupButton' onClick={this.signIn}>Go!</button>
                        </td></tr>
                        <tr><td className='forgotLink'>
                            <Link to={"/forgot"}>Forgot Password?</Link>
                        </td></tr>
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div> /***/
        )
    }
}







export default SigninForm;