import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  BrowserRouter,
} from "react-router-dom";
// import ReactLoading from 'react-loading';
import './index.css';
import './media.css';
import reportWebVitals from './reportWebVitals';

import Signin from './signin';
import Signup from './signup';
import ForgotPassword from './forgot';
import UpdateAccount from './updateAccount';
import Reset from './reset';
import QRReader from './QR-reader';
import Admin from './adminDashboard';
import Dashboard from './dashboard';
import CreateGoal from './creategoal';



class MainPage extends React.Component {
    constructor() {
        super();
        this.state = {
            user:{
                auth: 55
            },
            editGoal:0,
            objectives: [],
            limitobjectives: [],
            categories:[
                {id:1,category:"cat1"},
                {id:2,category:"cat2"},
                {id:3,category:"cat3"},
                {id:4,category:"cat4"},
                {id:5,category:"cat5"},
                {id:6,category:"cat6"}
            ],
            category:0,
        }
        this.signin = this.signin.bind(this);
        if (window.performance) {
            if (performance.navigation.type === 1) {
              let parsedData = JSON.parse(localStorage.getItem('bucketliststate'));
              if(parsedData !== null)
              {
                this.state = parsedData;
              }
            } else {
            }
        }
    }


    componentDidMount = () => {

    }

    
    filterByValue = (data, string) => {
        return data.filter(element => {
            return element.title.toLowerCase().includes(string) || element.description.toLowerCase().includes(string)
        });
    }
    filterByCatValue = (data, idIn) => {
        // console.log(data);
        return data.filter(element => {
            return element.category.toString() === idIn.toString()
        });
    }
    
    limitobjectives = e => {
        let term = e.target.value;
        let data;
        data = this.filterByValue(this.state.objectives,term);
        this.setState({
            limitobjectives:data,
        // },() => console.log(this.state.objectives))
        })
    }

    limitcategory = e => {
        let cat = e.target.value;
        console.log(cat);
        let data;
        data = this.filterByCatValue(this.state.objectives,cat.toString());
        this.setState({
            category:cat,
            limitobjectives:data
        // },() => console.log(this.state.category))
        })

    }

    signout = () => {
        this.setState({
            user:{}
        })
        // remove
        localStorage.removeItem('bucketliststate');
    }

    signin = (auth,userid,name,email) => {
        // console.log(auth,name,email)
        this.setState({
            user:{
                auth: auth,
                name: name,
                userid: userid,
                email: email
            },
        })
          
        // localStorage.setItem('involvedk12state', JSON.stringify(this.state));
        // this.setState({
        //     isLoading:false,
        // })
        localStorage.setItem('bucketliststate', JSON.stringify(this.state));
    }
    saveState = () => {
        localStorage.setItem('bucketliststate', JSON.stringify(this.state));
    }
    getIndex(value, arr, prop) {
        for(var i = 0; i < arr.length; i++) {
            if(arr[i][prop] === value) {
                return i;
            }
        }
        return -1; //to handle the case where the value doesn't exist
    }
    setEditProject = hold => {
        let holdx = -1;
        if(hold === -1)
        {
            // console.log("neg ones")
            holdx =-1;
        }else{
            for(var i = 0; i < this.state.objectives.length; ++i) {
                // console.log(hold + " sdf " + this.state.objectives[i].id)
                // console.log(typeof holdx)
                holdx = holdx + 1;
                if(parseInt(this.state.objectives[i].id) === parseInt(hold)) {
                    break;
                }
            }
        }
        // console.log(this.getIndex(hold,this.state.objectives,'id'))
        // console.log(hold + " setEditProject " + holdx + " an " + this.state.objectives.length)
//        let holdx = this.getIndex(hold,this.state.objectives);//the index in the array of the id that is passed in to function

        this.setState({
            editGoal:holdx,
        // },() => {
        //     console.log(this.state.editGoal)
        })
    }
    updatePainted = (idIn,valIn) =>
    {
        let newObjectives = this.state.objectives;
        if(idIn > -1)
        {
            var i = this.getTrueId(idIn);
            let newObj = this.state.objectives[i];
            newObj.painted = valIn;
            newObjectives[i] = newObj;
         
            this.setState({
                objectives:newObjectives
            })
        }
    }
    updateObjectivesNew = (idIn,object) =>
    {
        let newObjectives = [...this.state.objectives];
        if(idIn > -1)
        {
            var i = this.getTrueId(idIn);
            let newObj = this.state.objectives[i];
            newObj.title = object.title;
            newObj.description = object.description;
            newObj.categoryid = object.categoryid;
            newObjectives[i] = newObj;
         
            this.setState({
                objectives:newObjectives,
                limitobjectives:newObjectives
            // },() => {
            //     console.log(this.state.objectives)
            })
        }else{
            newObjectives.push(object);
            // let obje = this.state.limitobjectives.concat(object);
            // console.log('test');
            // console.log(newObjectives);
            this.setState({
                objectives:newObjectives,
                limitobjectives:newObjectives
                // limitobjectives: obje
            // },() => {
            //     console.log("TESTING");
            //     console.log(this.state.objectives)
            })
        }
    }

    updateObjectives = (obj) => {
        this.setState({
            objectives:obj,
            limitobjectives:obj,
        })
    }

    setCats = (obj) => {
        this.setState({
            categories:obj
        })
    }


    getTrueId = (index) =>{
        var i = 0
        for( i = 0; i < this.state.objectives.length; i++) {
            if(this.state.objectives[i].id === index) {
                break;
            }
        }
        return i;
    }

render() {

    let publicLinks = "publicLinks";
    let privateLinks = "privateLinks";
    // let privateAdminLinks = "privateLinks";
    // let privateTeacherLinks = "privateLinks";
    // let privateUserLinks = "privateLinks";
    // let privateQuizLinks = 'privateLinks';

    if(this.state.user.auth === 0){
        publicLinks = "privateLinks";
        privateLinks = "publicLinks";
    }else{
        publicLinks = "publicLinks";
        privateLinks = "privateLinks";
    }
    // console.log(this.state.user.auth + " pub" + publicLinks + " priv " + privateLinks);
    // let loadingBar;
    // if(this.state.isLoading){
    //     loadingBar = (<div className="loading-screen">
    //         <img src="./logo192.png" alt="Involved-Logo" className="signin-logo" />  
    //         <ReactLoading className="loading-bars" type={"bars"} color="grey" />
    //     </div>);
    // }

    return (
    <div>
        <Router>
        <div>
          <nav>
            <div className="hamburger" onClick={this.toggleNav}>
              <div className="topbun"></div>
              <div className="patty"></div>
              <div className="bottombun"></div>
            </div>
            <ul id="navList">
              <li id="user" className={privateLinks}>
              <Link to="/user">Home</Link>
              </li>
              <li id="signin" className={publicLinks}>
                <Link to="/signin">Sign In</Link>
              </li>
              <li id="signup" className={publicLinks}>
                <Link to="/signup">Sign Up</Link>
              </li>
              <li id="updateaccount" className={privateLinks}>
              <Link to="/updateaccount">Update Account</Link>
              </li>
              <li id="signin" className={privateLinks}>
                <Link to="/signin">Sign Out</Link>
              </li>
              {/* <li id="creategoal" className={privateQuizLinks}>
                <Link to="/creategoal">Create Goal</Link>
              </li> */}
            </ul>
          </nav>
        {
            // (this.state.isLoading?(loadingBar):(
          <Switch>
            <Route exact push path="/">
              <Signin setLoading={this.setLoading} signout={this.signout} signin={this.signin}/>
            </Route>
            <Route push path="/signin">
              <Signin setLoading={this.setLoading} signout={this.signout} signin={this.signin}/>
            </Route>
            <Route push path="/signup">
              <Signup setLoading={this.setLoading} />
            </Route>
            <Route push path="/updateaccount">
                <UpdateAccount user={this.state.user} setAccountData={this.setAccountData} />
            </Route>
            <Route push path="/user">
                <Dashboard 
                    saveState={this.saveState}
                    statein={this.state}
                    user={this.state.user}
                    setEditProject={this.setEditProject}
                    limtobjectives={this.limitobjectives}
                    limitcategory={this.limitcategory}
                    category={this.state.category}
                    updateObjectives={this.updateObjectives}
                    setCats={this.setCats}
                />
            </Route>
            <Route push path="/admin">
                <Admin user={this.state.user} />
            </Route>
            <Route push path="/forgot">
                <ForgotPassword />
            </Route>
            <Route push path="/qrreader">
                <QRReader />
            </Route>
            <Route push path="/reset">
                <Reset></Reset>
            </Route>
            <Route push path="/creategoal">
                <CreateGoal 
                    updateObjectives={this.updateObjectivesNew}
                    updatePainted={this.updatePainted} 
                    statein={this.state}>
                </CreateGoal>
            </Route>
          </Switch>
        //   ))
        }
        </div>
      </Router>
    </div>
    );
}
}


ReactDOM.render(
  <React.StrictMode>
        <BrowserRouter>
            <MainPage></MainPage>
        </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
