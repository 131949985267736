import React from 'react';
// import PointGiver from './pointgiver';
// import Messenger from './messenger';


class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.setSchool = this.setSchool.bind(this);
        this.state = {
            theirId:0,
            auth:0,
            userId:0,
            courses:[],
            school:0
        }

        let user = this.props.user;
        this.state.theirId = user['theirId'];
        this.state.auth = user['auth'];
        this.state.userId = user['userId'];
        this.state.courses = user['courses'];
        this.state.school = user['school'];
    }
    
    
    setSchool = inschool => {
        this.setState({
            school:inschool
        })
    }

    render() {
        const multiplesInDiv = (
            <div className='main'>
                {/* <div className='smallcard'>
                    <PointGiver setSchool={this.setSchool} school={this.state.school} userId={this.state.userId} auth={this.state.auth}></PointGiver>
                </div>
                <div className='smallcard'>
                    <Messenger  school={this.state.school} userId={this.state.userId} auth={this.state.auth} theirId={this.state.theirId} updateTheir={this.props.updateTheir}></Messenger>
                </div> */}
            </div>
        );

        return multiplesInDiv;
    }
}
/**TODO:
 * CLass manager needs to have class adder in it...
 */
export default Admin;