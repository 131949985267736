import React from 'react';
import { Redirect } from 'react-router-dom';

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editredirect:false,
            newredirect:false,
        }
        // console.log(this.props.statein.limitobjectives);
        
        if (window.performance) {
            if (performance.navigation.type === 1) {
              let parsedData = JSON.parse(localStorage.getItem('bucketliststate'));
              if(parsedData !== null)
              {
                this.state = parsedData;
              }
            } else {
            }
        }
    }

    componentDidMount = () => {
        fetch("https://bucketlist.burgertechnologies.com/api/goals/read.php?userid=" + this.props.user.userid).then(res => res.json()).then(result => {
            // console.log(result);
            this.props.updateObjectives(result);
        }).then(() => {
            fetch("https://bucketlist.burgertechnologies.com/api/category/read.php?projectid=0").then(res => res.json()).then(result => {
                // console.log(result);
                this.props.setCats(result);
            });
        }).then(() => {
            this.props.saveState();
        });
        
    }

    // search = e => {
    //     let content = e.target.value;
    //     console.log(content);
    // }

    addGoal = () => {        
        this.setState({
            newredirect:true,
        },() => {
            this.props.setEditProject(-1);
        })
    }



    editStuff = e => {
        let hold = parseInt(e.target.value) + 0;
        console.log("edit stuff " + hold);
        
        this.props.setEditProject(hold);
        
        this.setState({
            editredirect:true,
        })
    
    }

    render() {
         
        if(this.state.editredirect)
        {
            return (<Redirect push to={"/creategoal"} />);
        }
        if(this.state.newredirect)
        {
            return (<Redirect push to={"/creategoal"} />);
        }

        const multiplesInDiv = (
            <div className='dashboard'>
                <div>
                    <table className='dashboardTableH1Swatch'><tbody>
                    <tr><td className='dashboardh1'>
                        <h1>Dreams</h1>
                    </td><td>
                        <div className='dashboardSwatch'>
                            <table><tbody>
                            <tr><td>
                            <div id="completed" className='completedColorSwatch'></div>
                                </td><td>
                                <lable className="completedLabel" htmlFor="completed">Completed</lable>
                                </td></tr><tr><td>
                                <div id="incompleted" className='incompletedColorSwatch'></div>
                                </td><td>
                                <lable className="completedLabel" htmlFor="incompleted">Waiting To Be Realized</lable>
                            </td></tr>
                            </tbody>
                            </table>
                        </div>
                        </td></tr>
                    </tbody>
                    </table>
                </div>
                <div className='searchdiv'>
                <input className='searchbar' placeholder='Search' onKeyUp={this.props.limtobjectives} />
                <CategorySelect classNameIn='searchpicker' category={this.props.category} limitcategory={this.props.limitcategory} categories={this.props.statein.categories}></CategorySelect>
                </div>
                <table className="goalstable">
                    <thead></thead>
                    <tbody>
                        {
                            this.props.statein.limitobjectives.map(objective => {
                                return (
                                    <tr key={Math.random(50000)}
                                        className={(objective.painted===1?("painted"):("plain"))}
                                        // value={ parseInt(objective.id) + 0} 
                                        onClick={this.editStuff}
                                        >
                                        <td key={Math.random(50000)}>
                                            <button key={Math.random(50000)} 
                                                    className="editButton"
                                                    value={parseInt(objective.id) + 0} 
                                                    onClick={this.editStuff}>Edit</button></td>
                                        <td key={Math.random(50000)} 
                                        // value={ parseInt(objective.id) + 0} onClick={this.editStuff}
                                        >{objective.title}</td>
                                        <td key={Math.random(50000)} 
                                        // value={ parseInt(objective.id) + 0} onClick={this.editStuff}
                                        >{objective.description}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                    <tfoot></tfoot>
                </table>
                <div className="maincircle" onClick={this.addGoal}>
                    <div className="crossbar"></div>
                    <div className="postbar"></div>
                </div>
            </div>
        );

        return multiplesInDiv;
    }
}


class CategorySelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        // console.log(this.props.category + "test");
        const multiplesInDiv = (
            <select className={this.props.classNameIn} onChange={this.props.limitcategory} value={this.props.category}>
                <option key={Math.random(50000)} value={0} id={0} name={0}>All Types</option>
                {this.props.categories.map(category => {
                    return (
                        <option key={Math.random(50000)} value={category.id} name={category.id} id={category.id}>{category.category}</option>
                    )
                })}
            </select>
        );

        return multiplesInDiv;
    }
}



export default Dashboard;