import React from 'react';
import { Redirect } from 'react-router-dom';

class CreateGoal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboardirect: false,
            category: 0,
            time:0,
            drive:0,
            researched:0,
            percieved:0,
        }
    }

    componentDidMount = () => {
        if(this.props.statein.editGoal > -1)
        {
            let idIn = this.props.statein.objectives[this.props.statein.editGoal].id;
            let cat = this.props.statein.objectives[this.props.statein.editGoal].categoryid;
            
            this.setState({
                category:cat 
            })
            fetch("https://bucketlist.burgertechnologies.com/api/drivelevel/read.php?id=" + idIn).then(res => res.json()).then(result => {
                this.setState({
                    drive:result.scaleindex
                })
            });
            fetch("https://bucketlist.burgertechnologies.com/api/estminutes/read.php?goalid=" + idIn).then(res => res.json()).then(result => {
                this.setState({
                    time: result.quantity
                })
            });
            fetch("https://bucketlist.burgertechnologies.com/api/difficulty/read.php?goalid=" + idIn).then(res => res.json()).then(result => {
                this.setState({
                    percieved:result.percieved,
                    researched:result.researched
                })
            });
            fetch("https://bucketlist.burgertechnologies.com/api/category/read.php?id=" + idIn).then(res => res.json()).then(result => {
                this.setState({
                    category:result.category
                })
            });
            // fetch("https://bucketlist.burgertechnologies.com/api/requirements/read.php?goalid=" + idIn).then(res => res.json()).then(result => {
            //     console.log(result)
            // });
        }
    }

    gotoDash = () => {
        this.setState({
            dashboardirect: true,
        })
    }

    updateCategory = e => {
        let val = e.target.options[e.target.selectedIndex].value;
        this.setState({
            category: val,
        // },() => {
        //     this.props.updateCategory(val);
        })
    }


    updateTime = e => {
        let val = e.target.value;
        this.setState({
            time:val,
        })
    }

    updateDrive = e => {
        let val = e.target.value;
        this.setState({
            drive:val,
        })
    }

    updateResearched = e => {
        let val = e.target.value;
        this.setState({
            researched:val,
        })
    }
    updatePercieved = e => {
        let val = e.target.value;
        this.setState({
            percieved:val,
        })
    }

    // updateResearched = e => {
    //     let val = e.target.value;
    //     this.setState({
    //         researched:val,
    //     })
    // }


    updateGoal = () => {
        let title = document.getElementById("title").value;
        let description = document.getElementById("description").value;
        let idIn = this.props.statein.objectives[this.props.statein.editGoal].id;
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                id: idIn,
                // userid: this.props.statein.user.email,
                title: title,
                description: description,
                category: this.state.category,
                researched: this.state.researched,
                percieved:this.state.percieved,
                time:this.state.time,
                will:this.state.drive,
            }),
        };
        
        fetch("https://bucketlist.burgertechnologies.com/api/goals/update.php", requestOptions).then(res => res.json()).then(result => {
            console.log(result);
        }).then(() => {
            let obj = {
                title:title,
                description:description,
                categoryid: parseInt(this.state.category) + 0,
                researched: parseInt(this.state.researched) + 0,
                percieved:parseInt(this.state.percieved) + 0,
                time:parseInt(this.state.time) + 0,
                will:parseInt(this.state.drive) + 0,
            };
            // obj.title = title;
            // obj.description = description;
            this.props.updateObjectives(idIn,obj);
            this.gotoDash();
        });
    }

    createGoal = () => {
        console.log(this.props.statein.user.userid);
        let title = document.getElementById("title").value;
        let description = document.getElementById("description").value;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                title: title,
                userid: this.props.statein.user.userid,
                description: description,
                category: this.state.category,
                researched: this.state.researched,
                percieved:this.state.percieved,
                time:this.state.time,
                will:this.state.drive,
            }),
        };
        console.log(requestOptions);
        let idIn = 0;
        fetch("https://bucketlist.burgertechnologies.com/api/goals/create.php", requestOptions)
            .then(res => res.json())
            .then(result => {
            console.log(result);//"success");
            idIn = result.id;
        }).then(() => {
            let obj = {
                id: parseInt(idIn) + 0,
                userid: this.props.statein.user.userid,
                title:title,
                description:description,
                categoryid: parseInt(this.state.category) + 0,
                researched: parseInt(this.state.researched) + 0,
                percieved:parseInt(this.state.percieved) + 0,
                time:parseInt(this.state.time) + 0,
                will:parseInt(this.state.drive) + 0,
                painted:0,
                category: "give"
            };
            // obj.title = title;
            // obj.description = description;
            this.props.updateObjectives(-1,obj);
            this.gotoDash();
        });
    }

    updatePainted = e => {
        let valIn = 0;
        let inId = this.props.statein.objectives[this.props.statein.editGoal].id;
        if(e.target.checked)
        {
            valIn = 1;
        }
        // console.log(inId);
        this.props.updatePainted(inId,valIn);
        const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    id:inId,
                    painted:valIn
                }),
              };
            //   console.log(requestOptions);
          
            fetch("https://bucketlist.burgertechnologies.com/api/goals/update.php",requestOptions).then(res => res.json()).then(result => {
                console.log(result);
            });
    }

    submitGoal = () => {
        if (this.props.statein.editGoal === -1) {
            this.createGoal();
        } else {
            this.updateGoal();
        }
    }

    render() {
        if (this.state.dashboardirect) {
            return (<Redirect push to={"/user"} />);
        }
        const multiplesInDiv = (
            <div>
                <table className="createtable">
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td>
                                <label>Goals</label>
                            </td>
                            <td>
                                <input id="title" placeholder="title" defaultValue={(this.props.statein.editGoal > -1 ? ((undefined === this.props.statein.objectives[this.props.statein.editGoal])?"":(this.props.statein.objectives[this.props.statein.editGoal].title)) : "")} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Description</label>
                            </td>
                            <td>
                                <textarea id="description" placeholder="specs" defaultValue={(this.props.statein.editGoal > -1 ? ((undefined === this.props.statein.objectives[this.props.statein.editGoal])?"":(this.props.statein.objectives[this.props.statein.editGoal].description)) : "")} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Requirements</label>
                            </td>
                            <td>
                                <input id="requirements" placeholder="requirements" defaultValue={(this.props.statein.editGoal > -1 ? ((undefined === this.props.statein.objectives[this.props.statein.editGoal])?"":(this.props.statein.objectives[this.props.statein.editGoal].requirements)) : "")} />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <label>Category</label>
                            </td>
                            <td>
                        <CategorySelect updateCategory={this.updateCategory} category={this.state.category} categories={this.props.statein.categories}/>

                            </td>
                        </tr>
                        <PlusMinus
                            onchange={this.updateDrive}
                            value={(this.props.statein.editGoal > -1 ? this.state.drive : 0)}
                            valuekey={"will"}>
                        </PlusMinus>
                        {/* <PlusMinus
                            value={(this.props.statein.editGoal > 0 ? this.props.statein.objectives[this.props.statein.editGoal].cronology : 0)}
                            valuekey={"cronology"}>
                        </PlusMinus> */}
                        <PlusMinus
                            value={(this.props.statein.editGoal > -1 ? this.state.percieved : 0)}
                            valuekey={"difficultyest"}
                            onchange={this.updatePercieved}>
                        </PlusMinus>
                        <PlusMinus
                            value={(this.props.statein.editGoal > -1 ? this.state.researched : 0)}
                            valuekey={"difficultyresearched"}
                            onchange={this.updateResearched}>
                        </PlusMinus>
                        <PlusMinus
                            value={(this.props.statein.editGoal > -1 ? this.state.time : 0)}
                            valuekey={"esttimeinmin"}
                            onchange={this.updateTime}>
                        </PlusMinus>
                        {
                            ((this.props.statein.editGoal) > -1 ? (
                                <tr>
                                    <td>
                                        <label htmlFor="painted">Completed</label>
                                    </td><td>
                                        <label className="switch">
                                            <input onChange={this.updatePainted} id="painted" type="checkbox" checked={(this.props.statein.editGoal > -1 ? ((this.props.statein.objectives[this.props.statein.editGoal].painted===1)?(true):(false)) : false)}/>
                                            <span className="slider round"></span>
                                        </label>
                                    </td>
                                </tr>
                            ):(null))
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>
                                <button onClick={this.submitGoal}>Submit</button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        );

        return multiplesInDiv;
    }
}
class PlusMinus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const multiplesInDiv = (
            <tr>
                <td>
                    <label>{this.props.valuekey}</label>
                </td>
                <td>
                    <input 
                        onChange={this.props.onchange}
                        value={this.props.value}
                        id={this.props.valuekey} 
                        type="number" />
                </td>
            </tr>
        );
        return multiplesInDiv;

    }
}

class CategorySelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {
        const multiplesInDiv = (
            <select value={this.props.category} onChange={this.props.updateCategory}>
                <option key={Math.random(50000)} value={0} id={0} name={0}>Please Select</option>
                {this.props.categories.map(category => {
                    return (
                        <option key={Math.random(50000)} value={category.id} name={category.id} id={category.id}>{category.category}</option>
                    )
                })}
            </select>
        );

        return multiplesInDiv;
    }
}


export default CreateGoal;