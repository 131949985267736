import React from 'react';
//import ReactLoading from 'react-loading';


class Signup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
    }

    componentDidMount = () => {

    }

    signUp = () => {
        var email = document.getElementById('email').value;
        var emailVer = document.getElementById('emailVer').value;
        var pass = document.getElementById('pass').value;
        var passVer = document.getElementById('passVer').value;
        // var name = document.getElementById('name').value;

        if (email === '' ||
            emailVer === '' ||
            pass === '' ||
            passVer === ''
            //  ||
            // name === ''
            ) {
            this.props.setLoading(false);
            alert("You Left Something Out...")
        } else {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email,
                    emailVer: emailVer,
                    pass: pass,
                    passVer: passVer,
                    // name: name,
                }),
            };

            fetch("https://bucketlist.burgertechnologies.com/api/accounts/create.php", requestOptions).then(res => res.json()).then((result) => {
                alert(result.response);
                // this.props.setLoading(false);
                console.log(result);
            });
        }
    }


    compareEmails = () => {
        var email = document.getElementById('email').value;
        var emailVer = document.getElementById('emailVer').value;
        if (email !== emailVer) {
            document.getElementById('emailVerLabel').style.display = "block";
        } else {
            document.getElementById('emailVerLabel').style.display = "none";
        }
    }

    comparePasswords = () => {
        var pass = document.getElementById('pass').value;
        var passVer = document.getElementById('passVer').value;
        if (pass !== passVer) {
            document.getElementById('passVerLabel').style.display = "block";
        } else {
            document.getElementById('passVerLabel').style.display = "none";
        }
    }

    render() {
        const multiplesInDiv = (
            <div className='signinForm'>
                
                <div className='signinLogoDiv'>
                    <img className='signinLogo' src="./logo192.png" alt="Bucket List logo" />
                </div>
                <h1>Sign Up</h1>
                <label id='emailVerLabel' htmlFor='email' style={{ color: "red", display: 'none' }}>Emails Do Not Match</label>
                <label htmlFor='email'>Email</label>
                <input id='email' placeholder='JoeShmo@example.com' onKeyUp={this.compareEmails} />
                <label htmlFor='emailVer'>Email Verification</label>
                <input id='emailVer' placeholder='JoeShmo@example.com' onKeyUp={this.compareEmails} />
                <label htmlFor='pass'>Password</label>
                <label id='passVerLabel' htmlFor='pass' style={{ color: "red", display: 'none' }}>Passwords Do Not Match</label>
                <input type='password' id='pass' placeholder='********' onKeyUp={this.comparePasswords} />
                <label htmlFor='passVer'>Password Verification</label>
                <input type='password' id='passVer' placeholder='********' onKeyUp={this.comparePasswords} />
                <button className='signupButton' onClick={this.signUp}>Sign Up</button>
            </div>
        );

        return multiplesInDiv;
    }
}

// class SelectComp extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//         }
//     }
//     render() {
//         return (
//             <select id={this.props.id} onChange={this.props.passedOnChange} defaultValue={this.props.defaultValue}>
//                 <option defaultValue>Please Select A {this.props.idNameSelect}</option>
//                 {this.props.options.map(opt => (
//                     <option id={opt.id} key={opt.id + opt.name} >{opt.name}</option>
//                 ))}
//             </select>
//         )
//     }
// }

export default Signup;